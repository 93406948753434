/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-17",
    versionChannel: "nightly",
    buildDate: "2023-09-17T00:20:34.351Z",
    commitHash: "613c21a5d2d377acca9b7e71d74c64946646042e",
};
